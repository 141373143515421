//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
//* Routes List start
const LandingPage = lazy(() =>
  import('../../blocks/landingpage/src/LandingPage.web').then(module => ({
    default: module.StyledLandingPage
  }))
);
const FaqPage = lazy(() => import('../../blocks/interactivefaqs/src/Faq.web'));
const ErrorPage = lazy(() =>
  import('../../blocks/audittrail2/src/Audittrail2.web').then(module => ({
    default: module.StyledErrorPage
  }))
);
const TermsAndConditionsPage = lazy(() =>
  import('../../blocks/termsconditions/src/TermsAndConditions.web')
);
const RefundPolicy = lazy(() =>
  import('../../blocks/termsconditions/src/RefundPolicy.web')
);
const RolesandpermissionsWithStyle = lazy(() =>
  import('../../blocks/rolesandpermissions/src/Rolesandpermissions.web').then(
    module => ({ default: module.RolesandpermissionsWithStyle })
  )
);
const FoodOutletWithStyle = lazy(() =>
  import('../../blocks/rolesandpermissions/src/FoodOutlet.web').then(
    module => ({ default: module.FoodOutletWithStyle })
  )
);
const AboutUsWithStyle = lazy(() =>
  import('../../blocks/contentmanagement/src/AboutUs.web').then(module => ({
    default: module.AboutUsWithStyle
  }))
);

const OpeningTimesWithStyle = lazy(() =>
  import('../../blocks/rolesandpermissions/src/OpeningTimes.web').then(
    module => ({ default: module.OpeningTimesWithStyle })
  )
);

const ParkMapWithStyle = lazy(() =>
  import('../../blocks/catalogue/src/ParkMap.web').then(module => ({
    default: module.ParkMapWithStyle
  }))
);

const AllGoogleReviews = lazy(() =>
  import('../../blocks/googlereview/src/AllGoogleReview.web').then(module => ({
    default: module.StyledAllGoogleReviews
  }))
);

const AdventureActivityWithStyle = lazy(() =>
  import('../../blocks/settings2/src/Settings2.web').then(module => ({
    default: module.AdventureActivityWithStyle
  }))
);

const LoginWebWithStyle = lazy(() =>
  import('../../blocks/email-account-login/src/EmailAccountLoginBlock.web').then(
    module => ({ default: module.LoginWebWithStyle })
  )
);

const EmailAccountRegistrationWithStyle = lazy(() =>
  import('../../blocks/email-account-registration/src/EmailAccountRegistration.web').then(
    module => ({ default: module.EmailAccountRegistrationWithStyle })
  )
);

const TicketsystemWithStyle = lazy(() =>
  import('../../blocks/ticketsystem2/src/Ticketsystem2.web').then(module => ({
    default: module.TicketsystemWithStyle
  }))
);

const EventsAndGroups = lazy(() =>
  import('../../blocks/landingpage/src/EventsGroups.web').then(module => ({
    default: module.StyledEventsAndGroups
  }))
);

const GetInTouch = lazy(() =>
  import('../../blocks/automaticformcreation/src/AutomaticFormWebCreation.web').then(
    module => ({ default: module.StyledAutoForm })
  )
);

const FormSuccess = lazy(() =>
  import('../../blocks/landingpage/src/FormSuccess.web')
);

const CategoriessubcategoriesWithStyle = lazy(() =>
  import('../../blocks/categoriessubcategories/src/Categoriessubcategories.web').then(
    module => ({ default: module.CategoriessubcategoriesWithStyle })
  )
);

const StyledDashBoard = lazy(() =>
  import('../../blocks/dashboard/src/Dashboard.web').then(module => ({
    default: module.StyledDashBoard
  }))
);

const StyledContactUs = lazy(() =>
  import('../../blocks/contactus/src/Contactus.web').then(module => ({
    default: module.StyledContactUs
  }))
);

const MycartWithStyle = lazy(() =>
  import('../../blocks/ticketsystem2/src/Mycart.web').then(module => ({
    default: module.MycartWithStyle
  }))
);

const ForgotPasswordWithStyle = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPassword.web').then(
    module => ({ default: module.ForgotPasswordWithStyle })
  )
);

const ResetPasswordWithStyle = lazy(() =>
  import('../../blocks/forgot-password/src/ResetPassword.web').then(module => ({
    default: module.ResetPasswordWithStyle
  }))
);

const SummaryWithStyle = lazy(() =>
  import('../../blocks/ticketsystem2/src/Summary.web').then(module => ({
    default: module.SummaryWithStyle
  }))
);

const ReviewWithStyle = lazy(() =>
  import('../../blocks/ticketsystem2/src/Review.web').then(module => ({
    default: module.ReviewWithStyle
  }))
);
const EmailVerification = lazy(() =>
  import('../../blocks/email-account-registration/src/EmailVerification.web').then(
    module => ({ default: module.StyledEmailVerification })
  )
);
const PaymentSuccess = lazy(() =>
  import('../../blocks/razorpayintegration/src/PaymentSuccess.web').then(module => ({ default: module.StyledPaymentSuccess }))
);
import PrivateRoute from './PrivateRoute.web';
// /* Routes List End /

// /* Private Routes start /

// /* Private Roues End /

function WebRoutes(rootProps: any) {
  return (
    <Suspense fallback="">
      <Switch>
        <Route path="/" exact render={props => <LandingPage {...props} />} />
        <Route path="/faq" exact render={props => <FaqPage {...props} />} />
        <Route
          path="/TermsAndConditions"
          exact
          render={props => <TermsAndConditionsPage {...props} />}
        />
        <Route
          path="/PrivacyPolicy"
          exact
          render={props => <RefundPolicy {...props} />}
        />
        <Route
          path="/Signup"
          exact
          render={props => <EmailAccountRegistrationWithStyle {...props} />}
        />
        <Route
          path="/LogIn"
          exact
          render={props => <LoginWebWithStyle {...props} />}
        />
        <Route
          path="/ForgotPassword"
          exact
          render={props => <ForgotPasswordWithStyle {...props} />}
        />
        <Route
          path="/AboutUs"
          exact
          render={props => <AboutUsWithStyle {...props} />}
        />
        <Route
          path="/Reviews"
          exact
          render={props => <AllGoogleReviews {...props} />}
        />
        <Route
          path="/ParkInformation"
          exact
          render={props => <RolesandpermissionsWithStyle {...props} />}
        />
        <Route
          path="/ParkInformation/foodoutlet"
          exact
          render={props => <FoodOutletWithStyle {...props} />}
        />
        <Route
          path="/ParkInformation/openingtimes"
          exact
          render={props => <OpeningTimesWithStyle {...props} />}
        />
        <Route
          path="/ParkInformation/parkmap"
          exact
          render={props => <ParkMapWithStyle {...props} />}
        />
        <Route
          path="/ParkInformation/AboutUs"
          exact
          render={props => <AboutUsWithStyle {...props} />}
        />
        <Route
          path="/ParkInformation/ContactUs"
          exact
          render={props => <StyledContactUs {...props} />}
        />
        <Route
          path="/AdventureActivity"
          exact
          render={props => <AdventureActivityWithStyle {...props} />}
        />
        <Route
          path="/EventsAndGroups"
          exact
          render={props => <EventsAndGroups {...props} />}
        />
        <Route
          path="/GetInTouch"
          exact
          render={props => <GetInTouch {...props} />}
        />
        <Route
          path="/FormSuccess"
          exact
          render={props => <FormSuccess {...props} />}
        />
        <Route
          path="/AnnualPass"
          exact
          render={props => <CategoriessubcategoriesWithStyle {...props} />}
        />
        <Route
          path="/DayPass"
          exact
          render={props => <TicketsystemWithStyle {...props} />}
        />
        <PrivateRoute
          path="/Dashboard"
          exact
          render={props => <StyledDashBoard {...props} />}
        />
        <Route
          path="/ContactUs"
          exact
          render={props => <StyledContactUs {...props} />}
        />
        <Route
          path="/EmailVerification"
          exact
          render={props => <EmailVerification {...props} />}
        />
        <PrivateRoute
          path="/Cart"
          exact
          render={props => <MycartWithStyle {...props} />}
        />
        <Route
          path="/resetpassword"
          exact
          render={props => <ResetPasswordWithStyle {...props} />}
        />
        <Route
          path="/PaymentSuccess"
          exact
          render={props => <PaymentSuccess {...props} />}
        />
        <PrivateRoute
          path="/Summary"
          exact
          render={props => <SummaryWithStyle {...props} />}
        />
        <PrivateRoute
          path="/Review"
          exact
          render={props => <ReviewWithStyle {...props} />}
        />
        <Route path="*" exact render={props => <ErrorPage {...props} />} />
      </Switch>
    </Suspense>
  );
}

export default withRouter(WebRoutes);
