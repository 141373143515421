import './App.css'
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import 'antd/dist/antd.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-phone-input-2/lib/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-calendar/dist/Calendar.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}
class App extends Component {
  render() {
    return (
      <>
        <div style={{ height: '100vh' }}>
        <ToastContainer/>
         <WebRoutes/>
        </div>
      </>
    );
  }
}


export default App;



